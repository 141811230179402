import { usePageTitle } from '@capturi/react-utils'
import { t } from '@lingui/macro'
import React from 'react'

import { ScoreEditor, ScoreEditorProvider } from '../editor'

const CreateScorePage: React.FC = () => {
  usePageTitle(t`Create score`)

  return <ScoreEditor />
}

export default function CreateScorePageContainer(
  props: React.ComponentProps<typeof CreateScorePage>,
): React.ReactElement {
  return (
    <ScoreEditorProvider>
      <CreateScorePage {...props} />
    </ScoreEditorProvider>
  )
}
